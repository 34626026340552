$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
.registerContainer {
  height: 100% !important;
  min-height: calc(100vh - 60px) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.registerBoxContainer {
  border:  1px solid #dadce0;
  border-radius: 8px;
  -webkit-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  -moz-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  margin-bottom: 20px;
}
.registerBox {
  margin: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registerLogo {
  position: absolute !important;
  margin-left: 20px;
  margin-top: -20px;
}
.registerMainLogo {
  padding-top: 50px !important;
}
.registerBoxTitle {
  font-weight: 600;
}
.registerBoxDescription {
  margin-bottom: 15px;
  font-weight: 400;
}
.registerForm {
  margin-top: 40px !important;
  width: 100%;
}
.registerButton {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 150px !important;
  height: 40px !important;
}
.alreadyHaveAccountLink {
  float: right;
  position: absolute;
  right: 70px;
  bottom: 20px;
  color: black !important;
}
.alreadyHaveAccountLink.withTerms {
  bottom: 80px !important;
}
.termsRow { 
  margin-top: 10px;
}
.termsRowLabel {
  font-size: 16px !important;
  word-spacing: 2px;
}

/* Password */
.passwordTooltipIcon {
  position: absolute !important;
  top: 50% !important;
  right: -26px !important;
  font-size: 19px !important;
}
/* .passwordTooltip { } */
/* Confirmation page */
.registerConfirmationBoxContainer {
  border:  1px solid #dadce0;
  border-radius: 8px;
  -webkit-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  -moz-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  margin-bottom: 20px;
  min-width: 500px;
  max-width: 500px !important;
}
.registerConfirmationForm {
  width: 100%;
  margin-top: 20px !important;
}
.resendConfirmationLink {
  float: right;
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: rgba(0,0,0,0.65) !important;
}
.registerConfirmationButton {
  margin-top: 20px;
  margin-bottom: 40px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 200px !important;
  height: 40px !important;
}
/* Reset confirmation page */
.resetConfirmationButton {
  margin-top: 20px;
  margin-bottom: 40px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 200px !important;
  height: 40px !important;
}

/* Rules */
.rulePass > svg {
  color: green;
}
.ruleFail > svg {
  color: red;
}