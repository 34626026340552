$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
.data-display {
  display: flex;
  flex-direction: column;

  small {
    font-size: 12px;
    opacity: 0.5;
  }

  strong {
    font-size: 16px;
  }
}