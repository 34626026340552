$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
.signatureCanvas {
  border-style: dashed !important;
  border-color: black !important;
  border-width: 1px !important;
}
.signatureSaveButton {
  margin-left: 20px;
}
