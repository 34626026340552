$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
@import './CommonColors.scss';

.admEmpLink {
  color: lighten(map-get($shades-of-red, main), 10%) !important;;
}
.admEmpLink.disabled {
  opacity: .3;
  cursor: not-allowed;
}
.admEmpLinkIcon {
  margin-right: 5px !important;
}
.admEmpDivider {
  background-color: #dedede !important;
  margin-right: 0px !important;
}