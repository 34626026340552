$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
.batchEditForm {
  .ant-input[disabled], .ant-input-number-disabled, .ant-picker-input > input[disabled], .ant-input-affix-wrapper-disabled {
    color: grey !important;
  }
}
.batchEditTable {
  .ant-table-cell {
    padding: 8px 16px 8px 16px;
  }
}