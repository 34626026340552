$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
@import './CommonColors.scss';


.admMembLink {
  color: lighten(map-get($shades-of-red, main), 10%) !important;;
}
.admMembLink:hover {
  color: map-get($shades-of-red, main) !important;;
}
.admMembLink.disabled {
  opacity: .3;
  cursor: not-allowed;
}
.admMembLinkIcon {
  margin-right: 5px !important;
}
.admMembDivider {
  background-color: #dedede !important;
  margin-right: 0px !important;
}