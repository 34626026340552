$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
.menuBadge {
  background-color: #F04623;
  position: absolute;
  left: 42px;
  top: 1px;
  font-size: 9px;
  font-weight: 600;
  color: white;
  padding: 0px 2px 0 2px;
  border-radius: 11px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
}

.menuBadge.expanded {
  left: 135px;
}