$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
/* authorization/registration containers*/
.containerLinks {
  margin-top: -10px;
}
.containerBoxLinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.containerPrivacyLabel {
  margin-left: 30px;
  color: #7A7A7A;
  text-decoration: underline;
}
.containerHelpLabel {
  margin-right: 30px;
  color: #7A7A7A;
  text-decoration: underline;
}
