$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
@import './CommonColors.scss';

body {
  padding: 0;
  min-width: 300px !important;
}

/* Header stuff */
.appHeader {
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
  z-index: 1000 !important;
  height: 90px !important;
  background: map-get($shades-of-grey, darker) !important;
  border-bottom: 1.3px solid map-get($shades-of-grey, main);
  background-image: url("../images/textures-1.png") !important;

  &.squeeze {
    height: 50px !important;

    .headerContainer {
      margin-top: -8px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 650px) {
    padding: 0 20px !important;
  }

  .lighthingHeader {
    background-image: url("../images/lightning.png") !important;
    background-size: cover;
    height: 80px;
    width: 167px;
    left: 150px;
    top: 0;
    position: absolute;
    opacity: .9;
    @media screen and (max-width: 650px) {
      display: none;
    }
  }

  .headerContainer {
    width: 100%;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mobileHeaderMenu {
      margin-right: 20px;
      
      @media screen and (min-width: 651px) {
        display: none !important;
      }

      .menuIcon {
        background: transparent !important;
        border: transparent !important;
        outline: none !important;
      }
    }

    .headingLogo {
      height: 60px;

      &.squeeze {
        height: 35px;
      }

      @media screen and (max-width: 650px) {
        width: 120px;
      }
    }
    
    .userInfo {
      display: flex;
      align-items: center;
      line-height: 20px !important;
    
      .details {
        margin-right: 15px;
  
        @media screen and (max-width: 540px) {
          display: none;
        }
  
        span {
          color: #6A6A6A;
          font-size: 16px;
          display: block;
        }
        
        strong {
          font-size: 14px;
          color: #BAB8B8;
        }
      }
    }
  }
}


/* Navigation */
.sideNavigation {
  // box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.07) !important;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2) !important;
}
.sideNavigationMenu {
  padding-top: 20px !important;
  overflow-y: visible !important;
  overflow-x: hidden !important;
  font-size: 15px !important;
  * > svg {
    font-size: 18px !important;
  }
}
.ant-layout-sider { 
  padding: 0 !important;
}
// Collapse trigger
.ant-layout-sider-trigger {
  background: map-get($shades-of-grey, darker) !important;
  background-image: url("../images/textures-1.png") !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  width: 40px !important;
  position: 'absolute' !important;
  box-shadow: 4px 0px 10px 2px rgba(0, 0, 0, 0.1);
  left: 180px;
  svg { 
    color: white !important;
    font-weight: 800; 
    // right: -20px;
    // position: absolute;
    // top: -18px;
  }
}
.ant-layout-sider-collapsed > .ant-layout-sider-trigger  {
  left: 80px;
  border-top-left-radius: 0;
  border-top-right-radius: 6px;
}
// Custom icons
.customSideIcon {
  margin-bottom: -4px;
  margin-right: 10px;
}
.ant-layout-sider-collapsed {
  .customSideIcon {
    margin-bottom: -4px;
    margin-right: 10px;
  }
  .nav-text {
    opacity: 0;
  }
}

/* page container */
section.container-layout {
  height: 100vh !important;
}
.main-content {
  overflow: auto !important;
}
/* Page - w/ nav and header */
.pageHeader {
  margin-top: 10px !important;
  border-bottom-width: 1px;
}
.pageContent {
  padding: 30px;
  height: 100%;
}
.pageContentSubsection {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  padding: 0 !important;
}

/* Page - fullscreen */
.pageContentFullScreen {
  padding: 30px;
  min-height: 100% !important;
}
.pageContentSubsection .ant-card-body {
  padding: 0 !important;
}

/* Alert */
.alertController {
  z-index: 9999;
}

/* Default logo */
.authLogoRow {
  margin-top: -100px;
}
.bwLogo {
  height: 100px;
  margin-bottom: 20px;
}
.bwLogo-small {
  height: 25px;
  margin-bottom: 10px;
}

/* User Badge */
.idmBadgeContainer {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  opacity: 0.7 !important;
  transition: all 0.3s ease-in-out;

  &:hover { 
    opacity: 1 !important; 
  }

  .idmBadgeUserIcon {
    background: $primary-color !important;
    box-shadow: 0px 2px 3px 2px rgba(0,0,0,0.19) !important;
    font-size: 16px !important;
    border: 2px solid #fff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    span {
      color: #fff !important;
      font-weight: bold !important;
    }
  }
}