$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
.admin-member-form {
  .ant-col .ant-form-item-label {
    padding: 0 !important;

    label {
      font-size: 12px !important;
    }
  }

  input, .ant-picker, .ant-input-number-handler-wrap, .ant-select-selector {
    height: 25px !important;
  }

  .ant-row .ant-form-item {
    margin: -3px !important;
    padding: 0 !important;
  }

  input:disabled, input:disabled::placeholder {
    color: #000;
    font-weight: bold;
  }

  .bold-label {
    &.negative {
      label {
        color: #f00 !important;
      }
    }

    label {
      font-weight: bold;
    }
  }

  textarea {
    font-size: 12px !important;
    height: 80px !important;
  }

  .weight-normal {
    font-weight: normal !important;
    
    &, input:disabled, input:disabled::placeholder {
      font-weight: normal !important;
    }
  }
}
