$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
.summaryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;

    .summaryCol {
      font-size: 16px;
      line-height: 35px;

      > div {
        text-align: right;
      }

      &:last-child {
        margin-left: 35px;
      }
    }
  }

  .summaryTotal {
    font-size: 20px;
    margin-top: 10px;
  }
}