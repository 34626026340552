$primary-color: #D7503F;
$link-color: #D6664A;
$redMain: #D6664A;
$greyDark: #454545;
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 800px;
}

/* Password input */
.ReactPasswordStrength {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  line-height: 1.5;
  height: 36px;
  background-color: white;
  align-self: flex-start !important;
  align-content: flex-start !important;
  align-content: flex-start !important;
  justify-content: flex-start !important;
}
.ReactPasswordStrength-input {
  box-shadow: none !important;
  outline:0 !important;
  height: 34px;
}
.ReactPasswordStrength-strength-bar {
  margin-left: 3px !important;
  margin-right: auto !important;
  max-width: 98.8% !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ReactPasswordStrength-strength-desc {
  margin-top: -12px !important;
  width: 100px !important;
}
.registerBox .ReactPasswordStrength-input {
  margin-left: -18% !important;
}
.changePasswordTooltipIcon {
  position: absolute !important;
  top: 60% !important;
  right: -10px !important;
  font-size: 19px !important;
  color: #4B5660 !important;
}
.passwordTooltipIcon {
  position: absolute !important;
  top: 8px !important;
  right: -26px !important;
  font-size: 19px !important;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
  cursor: pointer;
}

.dispatch-members-buttons-sticky {
  background: #fff;
   width: 100%;
   display: flex;
   justify-content: flex-end;
   position: sticky;
   top: 0;
   z-index: 9;
   padding: 25px 0;
   box-shadow: 10px 10px 10px rgba(255, 255, 255, 1);
}
.squeeze-table {
  td, tr, .ant-table-cell {
    font-size: 13px;
    padding: 6px 3px !important;
  }
}

.squeeze-table-workhistory {
  td, tr, .ant-table-cell {
    font-size: 14px;
    padding: 6px 3px !important;
  }
}